import React from "react";
import styled from "styled-components";
import gridLines from "../../../assets/frame/footer-grid-lines.svg";
import FooterLogo from "../../../assets/logo/footer-logo.svg";
import privacyIcon from "../../../assets/Icons/svgs/privacy-icon.svg";
import termsIcon from "../../../assets/Icons/svgs/terms-icon.svg";
import contactUsIcon from "../../../assets/Icons/svgs/contact-us-icon.svg";
import { BREAKPOINTS } from "../../../theme/breakpoints";
import instagramIcon from "../../../assets/Icons/svgs/instagram-icon.svg";
import youtubeIcon from "../../../assets/Icons/svgs/youtube-icon.svg";
import twitterIcon from "../../../assets/Icons/svgs/twitter-icon.svg";
import { Link } from "react-router-dom";

const MainDiv = styled.div`
  height: 304px;
  align-self: stretch;
  width: 100%;
  display: flex;
  padding-top: 73px;
  background: #200c32;
  overflow: hidden;
  position: relative;
  @media (max-width: ${BREAKPOINTS.md}px) {
    padding-top: 69px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: flex;
    height: auto;
    flex-direction: column;
    padding-top: 40px;
    height: auto;
    padding-bottom: 108px;
  }
`;
const BottomRectangle = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 26px;
  padding: 10px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  background: #372547;
  backdrop-filter: blur(4px);
`;
const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 90px;
  position: relative;
  padding-left: 135px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    padding-left: 70px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    position: unset;
    padding-left: 18px;
  }
`;
const GridLinesFrame = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: none;
  }
`;
const Logo = styled.img`
  width: 153.865px;
  height: 34.905px;
  flex-shrink: 0;
  z-index: 11;
`;
const CopyRightText = styled.p`
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  font-family: "ClashGrotesk-Regular";
  z-index: 11;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 12px;
    line-height: 22px; /* 220% */
    position: absolute;
    bottom: 42px;
    left: 16px;
  }
`;
const RightSection = styled.div`
  display: flex;
  gap: 88px;
  position: absolute;
  right: 127px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    right: 43px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    flex-direction: column;
    padding-left: 18px;
    position: unset;
    margin-top: 51px;
    gap: 48px;
  }
`;
const RightColumn1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
`;
const RightColumn2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  /* Hidden for now-----> */
  display: none;
`;
const Heading = styled.p`
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 18px;
    line-height: 22px; /* 122.222% */
  }
`;

const Boxes = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
const AnkerText = styled.p`
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 122.222% */
  cursor: pointer;
  opacity: 0.4;
  font-family: "ClashGrotesk-Regular";
  &:hover {
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 16px;
    line-height: 22px; /* 137.5% */
  }
`;
const index = () => {
  return (
    <MainDiv>
      <LeftSection>
        <GridLinesFrame src={gridLines} alt="" />
        <Logo src={FooterLogo} alt="" />
        <CopyRightText>© 2024 ZOOOX</CopyRightText>
      </LeftSection>
      <RightSection>
        <RightColumn1>
          <Heading>Quick Links</Heading>
          <Link
            style={{ textDecoration: "none" }}
            to={"https://zooox.xyz/privacy-policy"}
            target="_blank"
          >
            <Boxes>
              <img src={privacyIcon} alt="" />
              <AnkerText>Privacy Policy</AnkerText>
            </Boxes>
          </Link>
          <Link
            style={{ textDecoration: "none", border: "none" }}
            to={"https://zooox.xyz/terms-of-use"}
            target="_blank"
          >
            <Boxes>
              <img src={termsIcon} alt="" />
              <AnkerText>Terms of Use</AnkerText>
            </Boxes>
          </Link>
          <Boxes>
            <img src={contactUsIcon} alt="" />
            <AnkerText>Contact Us</AnkerText>
          </Boxes>
        </RightColumn1>
        <RightColumn2>
          <Heading>Connect with Us</Heading>
          <Boxes>
            <img src={instagramIcon} alt="" />
            <AnkerText>Instagram</AnkerText>
          </Boxes>
          <Boxes>
            <img src={youtubeIcon} alt="" />
            <AnkerText>YouTube</AnkerText>
          </Boxes>
          <Boxes>
            <img src={twitterIcon} alt="" />
            <AnkerText>Twitter</AnkerText>
          </Boxes>
        </RightColumn2>
      </RightSection>
      <BottomRectangle />
    </MainDiv>
  );
};

export default index;
