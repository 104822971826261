import React from "react";
import Header from "../components/Layout/Header";
import styled from "styled-components";
import { BREAKPOINTS } from "../theme/breakpoints";

const MainDiv = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Heading = styled.div`
  margin-top: 62px;
  color: #200c32;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 50.958px; /* 106.162% */
  letter-spacing: -1.44px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 40px;
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 24px;
    margin-top: 55px;
    text-align: start;
    width: 90%;
  }
`;
const Text = styled.p`
  margin-top: 48px;
  text-align: justify;
  width: 70%;
  color: #7f7f7f;
  font-size: 18px;
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.18px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 80%;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 90%;
    font-size: 16px;
    margin-top: 20px;
  }
`;
const SmallHeading = styled.p`
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.18px;
`;
const TermsOfUse = () => {
  return (
    <MainDiv>
      <Header />
      <Heading>Terms of use</Heading>
      <Text>
        These terms of use (“Terms”) mandate the terms on which the users (“You”
        or “Your” or “Yourself” or “User”) access and register on the
        website www.zooox.org and mobile application, collectively referred to
        as (“the Platform”).
        <br />
        <br />
        These Terms are an electronic record in terms of the Information
        Technology Act, 2000 and rules made thereunder as applicable and are
        generated by a computer system and does not require any physical,
        electronic, or digital signatures.
        <br />
        <br />
        Please read these Terms and the Privacy Policy carefully before using or
        registering on the Platform or accessing any material, information,
        etc., through the Platform. By clicking on the “Continue” button
        provided at the signup page, You accept these Terms, the Privacy Policy
        and agree to be legally bound by the same.   
        <br />
        <br />
        The Company retains an unconditional right to modify or amend these
        Terms and the Privacy Policy and notify You of the same. You can
        determine when these Terms were last modified by referring to the “Last
        Updated” legend above. It shall be Your responsibility to check the
        Terms periodically for changes. Your acceptance of the amended Terms
        shall signify Your consent to such changes and agreement to be legally
        bound by the same. Further, by furnishing Your Personal Information to
        the Company or giving Your consent to the Company to access Your
        Personal Information for the purpose of rendering the Services (as
        defined below), You also agree that You are interested in availing the
        Services through the Platform in accordance with these Terms. ‍
        <br />
        <br />
        <SmallHeading>Services</SmallHeading>
        <br />
        You acknowledge that the Platform allows You to avail the following
        services including: License to the Platform: The Company provides You a
        limited, non–exclusive, non–transferable, royalty free license to use
        the Platform for the purposes of registering on the Platform, accessing
        the Content (as defined below) and availing the Services through the
        Platform. You can register on the Platform using Your ZOOOX ID (defined
        later) e-mail address or any other email address provided by other email
        service providers (“Registered e-mail”).‍
        <br />
        <br />
        ZOOOX ID: You can sign-up on the Platform to get an email account
        provided by the Company (“ ZOOOX ID”), which can be used to sign up on
        third party e-commerce sites. Signing up on third party e-commerce sites
        using ZOOOX ID shall entitle You to additional rewards on the Platform.‍
         
        <br />
        <br />
        Seamless order tracking: You will be able to see Your current order
        status, expected delivery date, etc., in a consolidated manner on the
        Platform.‍  
        <br />
        <br />
        Insight on the online shopping spendings: Company shall track and
        consolidate Your spendings details in terms of amount per order, types
        of goods or services availed, spends during a month, preferred websites
        etc. Such details will be collectively displayed on the Platform to
        provide You with an understanding of Your shopping habits. ‍  
        <br />
        <br />
        ‍Promotional offers and Cashbacks: You may be eligible to receive
        rewards, promotions, discounts and cashbacks for using the Platform and
        ZOOOX ID (“Promotional Offers”). For more details on Promotional Offers,
        please refer to the supplemental terms and conditions on Promotional
        Offers below.   
        <br />
        <br />
        The aforementioned services shall be collectively referred to as the
        “Services”. The Company may, at its discretion, add, modify, or remove
        any of the Services listed above from time to time.   ‍
        <br />
        <br />
        <SmallHeading>Provision of Services</SmallHeading>
        <br />
        You agree and acknowledge that the Company only facilitates availing of
        the Services on the Platform. The Services availed by You through the
        Platform are separate from the services or products availed by You from
        third party service providers. You acknowledge that under no
        circumstances should You expect the Company to be responsible for any
        deficiency or failure in the services or products provided to you by the
        third-party service providers. The services and products provided by
        third party service providers may be subject to separate terms and
        conditions or such other agreement between You and the concerned service
        provider(s), You agree and acknowledge that the Company is neither a
        party to such terms nor will it be liable thereunder for any reason
        whatsoever. The Company will club the information about the products or
        services of different service providers and list them in a consolidated
        manner on its Platform for the benefit of the Users.  
        <br />
        <br />
        As part of the Services provided to You, You agree to provide honest
        feedback/review about Our Services, if required by the Company.  
        <br />
        <br />
        The Company does not: (i) adopt any ‘unfair trade practices’ either on
        the Platform or otherwise with respect to the Services; and (ii)
        discriminate between Users of the same class or makes any arbitrarily
        classification of the Users.
        <br />
        <br />
        <SmallHeading>Reward Points</SmallHeading>
        <br />
        Reward Points can be earned by signing up on the Platform, referring
        friends to the Platform, (upon such friend signing up and creating a
        ZOOOX ID), for each successful e-commerce transaction that You undertake
        which is recorded on the Platform or using ZOOOX ID. You will be
        eligible to receive reward points on an e-commerce transaction only upon
        its successful completion (i.e., only after a delivery confirmation
        email is received from the merchant on your ZOOOX ID). You can redeem
        the reward points for discounts, vouchers, gift cards etc. These reward
        points are rewarded purely for participating in the Platform and the
        Platform does not permit any purchase of these reward points in any
        other way. Company also does not permit purchase of the reward points
        using fiat currency or any other mode. 
        <br />
        You can redeem the reward points against coupons, e-vouchers, gift cards
        etc on the Platform or for discounts on third party partner brands. The
        Company has partnered with multiple third-party brands, and a User,
        while placing orders with such third-party brands, can use their ZOOOX
        ID and redeem their Reward Points for discounts on products purchased
        from these brands.
        <br />
        <br />
        Company reserves the right, at its sole discretion, to change the rate
        number of cashback to be Reward Points received by a user in proportion
        to his/her the User’s activities. Cashback Reward Points credited to
        Your Account in respect of an e-commerce transaction will be reversed by
        the Company in case of any return or cancellation of such e-commerce
        transaction by You or the Merchant. Further, Company reserves the right
        to disqualify any User, who does not meet the offer requirements or for
        any other reason including but not limited to any misuse of the offer or
        fraud or suspicious transaction/activity or under any legal requirement
        or applicable rules and regulations, from receiving any cashback amount.
        Reward Points. Company also reserves the right to discontinue or change
        its cashback policy or issue any new form of Reward Points offered at
        any time, at its sole discretion. Company may also at its discretion
        specify an expiry period for the cashback amount. You agree and
        acknowledge that the Company shall not be obligated to redeem the
        cashback amount in any circumstance including on suspension of Your
        Account Reward Points.
        <br />
        <br />
        Please refer to the Supplemental terms and conditions on Reward Points
        below for more details on Reward Points redemption.  
        <br />
        <br />
        Reward Points are valid for a period of 12 months from the date of
        issuance. Any non-usage of the Platform for 3 months or more would lead
        to deduction of [100] Reward Points for every month of non-usage. For
        example, if a user is inactive for 4 months, they would lose 100 Reward
        Points for the 4th month. If they remain inactive for 5 months, they
        would lose 200 Reward Points (100 for the 4th month and 100 for the 5th
        month).  
        <br />
        <br />
        Company hereby clarifies that it does not operate a prepaid payment
        instrument (PPI) and any redemption of the Reward Points to Your bank
        account. Reward Points for discounts, vouchers, gift cards or cash does
        not tantamount to withdrawals from a PPI.   
        <br />
        <br />
        <SmallHeading>Eligibility to Use</SmallHeading>
        <br />
        The Use and access to the Platform is available to all persons who can
        be legally bound by contract and who are not disqualified or declared
        incompetent under the Indian Contract Act, 1872. You hereby represent
        that You are legally qualified to enter a binding contract, are not a
        person barred from receiving Services from the Platform under the
        applicable laws or by a regulatory authority, are a resident Indian as
        per the Indian foreign exchange and tax laws and are competent to enter
        into a binding contract.
        <br />
        <br />
        We maintain the right to provide Services to only those Users who are –
        a) competent to enter into legally binding contracts, b) have made the
        representations as provided above, c) and also qualify under the
        internal policy(ies) for the same determined solely by Us from time to
        time. We shall have the sole right to change, modify, add, or remove, in
        whole or in part, internal policy(ies), in relation to the provision of
        the Services at any time by providing a prior written notice or
        intimation to the Users.  
        <br />
        <br />
        You shall not have more than one active Account (as defined below) on
        the Platform. Additionally, You are prohibited from selling, trading, or
        otherwise transferring Your Account to another party or impersonating
        any other person for the purpose of creating an Account on the Platform.
         
        <br />
        <br />
        <SmallHeading>User Account, Password and Security</SmallHeading>
        <br />
        You may browse certain sections of the Platform without being registered
        with Us. However, to avail the Services on the Platform, You are
        required to set up an account on the Platform by providing such
        information as may be required by the Company as provided under the
        Privacy Policy (“Account”). You can access the Platform by logging into
        Your Account with Your credentials. 
        <br />
        <br />
        You shall ensure and confirm that the Account information provided by
        You is complete, accurate and up-to-date. If there is any change in the
        Account information, You shall promptly update Your Account information
        on the Platform.   
        <br />
        <br />
        If You provide any information that is untrue, inaccurate, not current
        or incomplete (or becomes untrue, inaccurate, not current or
        incomplete), or if the Company has reasonable grounds to suspect that
        such information is untrue, inaccurate, not current or incomplete, the
        Company has the right to refuse or reject the provision of Services.  
        <br />
        <br />
        You will be responsible for maintaining the confidentiality of the
        Account information and are fully responsible for all activities that
        occur under Your Account. You agree to immediately notify the Company of
        any unauthorized use of Your Account information or any other breach of
        security. The Company cannot and will not be liable for any loss or
        damage arising from Your failure to comply with this provision. You may
        be held liable for losses incurred by the Company or any other user of
        or visitor to the Platform due to authorized access or unauthorized use
        of Your Account as a result of Your failure in keeping Your Account
        information secure and confidential.   
        <br />
        <br />
        You hereby acknowledge and agree that the deletion of the Platform from
        Your device does not constitute termination of Your Account and agree to
        undertake the process detailed herein in order to complete the
        de-registration of Your Account (“De-registration”). If and when You are
        desirous of having Your name and other details removed from Our records,
        immediately upon receiving Your written request to that effect at
        support@zooox.org, We shall remove and/delete all such information.
        However, we may retain certain information regarding Your Account, even
        after receiving a deletion request from You, where such retention is
        required under applicable laws. You hereby acknowledge that the removal
        of Your details from the Platform does not constitute termination of
        Your outstanding obligations, if any, to the Company.   
        <br />
        <br />
        In case of no user activity for 6 months, You will be intimated via push
        notifications or any other communication channels for Your attention.
        Your account will be deleted subject to no user activity post 12 months
        of consideration.‍  
        <br />
        <br />
        <SmallHeading>Use of the Platform</SmallHeading>
        <br />
        Subject to compliance with these Terms, the Company hereby grants You a
        non-exclusive, limited privilege to access and use the Platform. You
        agree to use the Platform only: (a) for purposes that are permitted by
        the Terms; and (b) in accordance with any applicable law, regulation or
        generally accepted practices or guidelines. You agree not to engage in
        activities that may adversely affect the use of the Platform by the
        Company or other Users.  
        <br />
        <br />
        You agree not to access (or attempt to access) the Platform by any means
        other than through the interface that is provided by the Company. You
        shall not use any deep-link, robot, spider or other automatic device,
        program, algorithm or methodology, or any similar or equivalent manual
        process, to access, acquire, copy or monitor any portion of the Platform
        or Content, or in any way reproduce or circumvent the navigational
        structure or presentation of the Platform, materials or any Content, to
        obtain or attempt to obtain any materials, documents or information
        through any means not specifically made available through the Platform.
         
        <br />
        <br />
        You acknowledge and agree that by accessing or using the Platform, You
        may be exposed to content from others that You may consider offensive,
        indecent, or otherwise objectionable. The Company disclaims all
        liabilities arising in relation to such offensive content on the
        Platform.  
        <br />
        <br />
        Further, You undertake not to host, display, upload, modify, publish,
        transmit, store, update or share any information that:  
        <br />
        <br />
        belongs to another person and to which the User does not have any right;
        <br />
        is defamatory, obscene, pornographic, pedophilic, invasive of another's
        privacy, including bodily privacy, insulting, or harassing on the basis
        of gender, libelous, racially, or ethnically objectionable, relating or
        encouraging money laundering or gambling, or otherwise inconsistent with
        or contrary to the laws in force;
        <br />
        is harmful to child;
        <br />
        infringes any patent, trademark, copyright, or other proprietary rights;
        <br />
        violates any law for the time being in force;
        <br />
        deceives or misleads the addressee about the origin of the message or
        knowingly and intentionally communicates any information which is
        patently false or misleading in nature but may reasonably be perceived
        as a fact; impersonates another person;
        <br />
        threatens the unity, integrity, defense, security or sovereignty of
        India, friendly relations with foreign States, or public order, or
        causes incitement to the commission of any cognisable offense or
        prevents investigation of any offense or is insulting other nation;
        <br />
        contains software virus or any other computer code, file or program
        designed to interrupt, destroy, or limit the functionality of any
        computer resource;
        <br />
        s patently false and untrue, and is written or published in any form,
        with the intent to mislead or harass a person, entity, or agency for
        financial gain or to cause any injury to any person;
        <br />
        disrupt or interfere with the security of, or otherwise cause harm to,
        the Platform, systems resources, accounts, passwords, servers, or
        networks connected to or accessible through the Platform or any
        affiliated or linked sites; violate the Terms contained herein or
        elsewhere and/or the Privacy Policy; and
        <br />
        reverse engineer, modify, copy, distribute, transmit, display, perform,
        reproduce, publish, license, create derivative works from, transfer, or
        sell any information or software obtained from the Platform.
        <br />
        <br />
        You shall solely be responsible for maintaining the necessary computer
        equipment, mobile device and internet connections that may be required
        to access, use, and transact on the Platform.  
        <br />
        <br />
        Except as expressly indicated herein, the Company hereby grants You a
        non-exclusive, freely revocable (upon notice from the Company),
        non-transferable access to view any Content available on the Platform,
        subject to the following conditions:
        <br />
        <br />
        You may access the Content solely for personal or informational
        purposes, in accordance with the Terms;
        <br />
        You may not modify or alter the Content available on the Platform;
        <br />
        You may not distribute, copy, reproduce, or sell, rent, lease, license
        or otherwise make any Content on the Platform available to others; and
        <br />
        You may not remove any text, copyright or other proprietary notices
        contained in the Content downloaded from the Platform.
        <br />
        <br />
        Your use of the Platform shall be only for personal purposes. Your use
        of the Platform shall indicate that You have provided consent to
        automatically receive updates such as bug fixes, patches, enhanced
        functions, missing plug-ins and new versions (collectively, ‘Updates’),
        for the purpose of effective delivery of the Services. Please note that
        Your continued use of the Platform following such Updates would mean
        deemed acceptance by You of the same.  
        <br />
        <br />
        <SmallHeading>Intellectual Property Rights</SmallHeading>
        <br />
        The Platform and the processes, and their selection and arrangement,
        including but not limited to, all text, reports generated, videos,
        graphics, user interfaces, visual interfaces, sounds and music (if any),
        artwork and computer code (and any combinations thereof) and any
        information or materials uploaded by the Users or information or
        materials parsed by the Company from the User’s linked email accounts
        (collectively, the “Content”) on the Platform is owned and controlled by
        the Company and the design, structure, selection, coordination,
        expression, look and feel and arrangement of such Content is protected
        by copyright, patent and trademark laws, and various other intellectual
        property rights. You are not entitled to duplicate, distribute, create
        derivative works of, display, or commercially exploit the Content,
        features or facilities, directly or indirectly, without Our prior
        written permission. If You would like to request permission in respect
        of any particular Content, You can contact Us in the manner provided for
        in these Terms.
        <br />
        <br />
        The trademarks, logos and service marks displayed on the Platform
        (“Marks”) are the property of the Company or other respective third
        parties, as the case may be. You are not permitted to use the Marks
        without the prior consent of the Company or the third party that may own
        the Marks.  
        <br />
        <br />
        You agree and acknowledge that the Company reserves the right to use any
        recourse available to it under applicable laws if it believes that You
        have infringed any of its intellectual property rights.   
        <br />
        <br />
        <SmallHeading>Disclaimers of Warranties and Liabilities</SmallHeading>
        <br />
        You expressly understand and agree that, to the maximum extent permitted
        by applicable law that:
        <br />
        The Platform and other Content is provided by the Company on an “as is”
        basis without  warranty of any kind, express, implied, statutory, or
        otherwise, including the implied warranties of title, non-infringement,
        merchantability, or fitness for a particular purpose. Without limiting
        the foregoing, the Company makes no warranty that (i) the Platform or
        Services will meet Your requirements, or Your use of the Platform will
        be uninterrupted, timely, secure or error-free; (ii) the results that
        may be obtained from the use of the Platform will be effective, accurate
        or reliable; and (iii) any errors or defects in the Platform will be
        corrected. No advice or information, whether oral or written, obtained
        by You from the Company shall create any warranty not expressly stated
        in the Terms.
        <br />
        <br />
        Company will have no liability related to any Content arising under
        intellectual property rights, libel, privacy, publicity, obscenity, or
        other laws. Company also disclaims all liability with respect to the
        misuse, loss, modification, or unavailability of any Content.  
        <br />
        <br />
        Company will not be liable for any loss that You may incur as a
        consequence of unauthorized use of Your Account or Account information
        in connection with the Platform either with or without Your knowledge.  
        <br />
        <br />
        Company has endeavored to ensure that all the information on the
        Platform is correct, but the Company neither warrants nor makes any
        representations regarding the quality, accuracy or completeness of any
        data, information regarding the Services or otherwise. The Company shall
        not be responsible for the delay or inability to use the Platform or
        related functionalities, the provision of or failure to provide
        functionalities, or for any information, software, functionalities and
        related graphics obtained through the Platform, or otherwise arising out
        of the use of the Platform, whether based on contract, tort, negligence,
        strict liability or otherwise. Further, the Company shall not be held
        responsible for non-availability of the Platform during periodic
        maintenance operations or any unplanned suspension of access to the
        Platform that may occur due to technical reasons or for any reason
        beyond the Company’s control. You understand and agree that any material
        or data downloaded or otherwise obtained through the Platform is done
        entirely at Your own discretion and risk, and that You will be solely
        responsible for any damage to Your computer systems or loss of data that
        results from the download of such material or data.   
        <br />
        <br />
        These Terms are intended for Users within the territory of India and
        govern Your rights as per applicable law within the territory of India.
        However, in the event You fall under a jurisdiction outside the purview
        of Indian law, We will not be liable for any claim, action and/or right
        initiated/exercised by You as per the extant laws of that jurisdiction.
        Therefore, We request You to kindly use the Platform accordingly.   
        <br />
        <br />
        <SmallHeading>Indemnification and Limitation of Liability</SmallHeading>
        <br />
        You agree to indemnify, defend and hold harmless the Company, and its
        affiliates including but not limited to its (and its affiliates’)
        officers, directors, consultants, agents, representatives and employees
        (“Indemnitees”) from and against any and all losses, liabilities,
        claims, damages, demands, costs and expenses (including reasonable legal
        fees) asserted against or incurred by the Indemnitees that arise out of,
        result from, or may be payable by virtue of, any breach or
        non-performance of any representation, warranty, covenant or agreement
        made or obligation to be performed by You pursuant to these Terms and/or
        the Privacy Policy. Further, You agree to hold the Indemnitees harmless
        against any claims made by any third party due to, or arising out of, or
        in connection with, Your use of the Platform, any misrepresentation with
        respect to the data or information provided by You, Your violation of
        the Terms and/or the Privacy Policy, Your violation of applicable laws,
        or Your violation of any rights of another, including any intellectual
        property rights.  
        <br />
        <br />
        In no event shall the Company and its officers, partners, consultants,
        agents and employees, be liable to You or any third party for any
        special, incidental, indirect, consequential or punitive damages
        whatsoever, arising out of or in connection with Your use of or access
        to the Platform or Content on the Platform.   
        <br />
        <br />
        The limitations and exclusions in this Section apply to the maximum
        extent permitted by applicable laws.   
        <br />
        <br />
        <SmallHeading>Infringement of Intellectual Property</SmallHeading>
        <br />
        If You believe the Platform violates Your intellectual property, You
        must promptly notify the Company in writing at support@zooox.org. These
        notifications should only be submitted by the owner of the intellectual
        property, or an agent authorized to act on his/her behalf. However, any
        false claim by You may result in the termination of Your access to the
        Platform. You are required to provide the following details in Your
        notice:   
        <br />
        <br />
        the intellectual property that You believe is being infringed;
        <br />
        the item that You think is infringing and include sufficient information
        about where the material is located on the Platform;
        <br />
        a statement that You believe in good faith that the item You have
        identified as infringing is not authorized by the intellectual property
        owner, its agent, or the law to be used in connection with the
        Platform; 
        <br />
        Your contact details, such as Your address, telephone number, and/or
        email;  a statement that the information You provided in Your notice is
        accurate, and that You are the intellectual property owner, or an agent
        authorized to act on behalf of the intellectual property owner whose
        intellectual property is being infringed; and 
        <br />
        Your physical or electronic signature.
        <br />
        <br />
        <SmallHeading>Violation of the Terms</SmallHeading>
        <br />
        You agree that any violation by You of these Terms will constitute an
        unlawful and unfair business practice, and will cause irreparable harm
        to the Company, for which monetary damages would be inadequate, and You
        consent to the Company obtaining any injunctive or equitable relief that
        they deem necessary or appropriate in such circumstances. These remedies
        are in addition to any other remedies that the Company may have at law
        or in equity.
        <br />
        <br />
        <SmallHeading>Suspension and Termination</SmallHeading>
        <br />
        These Terms will continue to apply until terminated by either You or the
        Company as set forth below. If You object to the Terms or are
        dissatisfied with the Platform, Your only recourse is to (i) close Your
        Account on the Platform; and/or (ii) stop accessing the Platform.   
        <br />
        <br />
        The Company may delist You or block Your future access to the Platform
        or suspend or terminate Your Account (including cancellation of the
        unclaimed reward points rewarded to You) if it believes, in its sole and
        absolute discretion that You have infringed, breached, violated, abused,
        or unethically manipulated or exploited any term of these Terms or the
        Privacy Policy or anyway otherwise acted unethically. Notwithstanding
        anything in this clause, these Terms will survive indefinitely unless
        and until the Company chooses to terminate them.  
        <br />
        <br />
        If You or the Company terminate Your use of the Platform, the Company
        may delete any Content or other materials relating to You and the
        Company shall have no liability to You or any third party for doing so.
        However, Your transaction details may be preserved by the Company for
        purposes of tax or regulatory compliance. Governing Law and Jurisdiction
        <br />
        These Terms shall be governed by and constructed in accordance with the
        laws of India without reference to conflict of laws principles and
        disputes arising in relation hereto shall be subject to the exclusive
        jurisdiction of courts at Noida, Uttar Pradesh, India.
        <br />
        <br />
        Report Abuse
        <br />
        In the event You come across any abuse or violation of these Terms or if
        You become aware of any objectionable content on the Platform, please
        report the same to the following e-mail id: support@zooox.org  
        <br />
        <br />
        Communications
        <br />
        You hereby expressly agree to receive communications by way of calls
        (including using pre-recorded messages or artificial voice), SMS, emails
        and/or WhatsApp from the Company and other third parties duly authorized
        by the Company. You certify, warrant and represent that the telephone
        numbers and/or email addresses and any other information that You have
        provided to Us are Your own and are true, accurate, current and
        complete. You agree to notify Us whenever You stop using a particular
        telephone number(s) and/or email address(es). You can unsubscribe or
        opt-out from receiving communications through calls, SMS, e-mail, and
        WhatsApp anytime by writing to Us at [support@zooox.org] and in such
        cases, the Company will only send You communications solely required for
        the purposes of the Services provided through the Platform.  
        <br />
        <br />
        Grievance Redressal Mechanism
        <br />
        Grievance Handling: If You have any grievances, or complaints or
        concerns with respect to the Platform, the Content, or the Services, You
        can contact the designated Grievance Officer of the Company, namely,
        Mayank Chauhan, at mayank@zooox.org
        <br />
        By lodging a complaint or grievance, You agree to provide complete
        support to the Grievance Officer and such reasonable information as may
        be sought by them from You.  
        <br />
        <br />
        General Provisions
        <br />
        All Services on the Platform are provided to You on a
        principal-to-principal basis between You and the Company. Nothing herein
        is intended to nor be construed to constitute the relationship of a
        principal and agent, employer and employee, partners, joint venture,
        co-owners or otherwise as participants in a joint undertaking or
        representative of the other for any purpose whatsoever.  
        <br />
        <br />
        ‍Notice: All notices from the Company will be served by email to Your
        registered email address or by general notification on the Platform.   
        <br />
        <br />
        ‍Assignment: You cannot assign or otherwise transfer these Terms, or any
        rights granted hereunder to any third party. The Company’s rights under
        the Terms are freely transferable by the Company to any third party
        without the requirement of seeking Your consent.
        <br />
        ‍Severability: If, for any reason, a court of competent jurisdiction
        finds any provision of the Terms, or any portion thereof, to be
        unenforceable, that provision shall be enforced to the maximum extent
        permissible so as to give effect to the intent of the parties as
        reflected by that provision, and the remainder of the Terms shall
        continue in full force and effect.
        <br />
        <br />
        ‍Waiver: Any failure by Company to enforce or exercise any provision of
        the Terms, or any related right, shall not constitute a waiver by
        Company of that provision or right.  
        <br />
        <br />
        Supplemental terms and conditions for ‘Promotional Offers’and ‘Reward
        Points’  
        <br />
        <br />
        You shall be eligible to get ‘Launch Offers’, ‘ZOOOX Streaks’ and ‘Brand
        Streaks’ (together referred as “Promotional Offers”) for registering on
        the Platform and transacting on third party e-commerce sites using ZOOOX
        ID. The Promotional Offers can be in the form of coupons, vouchers,
        discounts, complementary memberships, etc., (“Benefits”). Promotional
        Offers can also be provided in the form of Reward Points issued by the
        Company. The following terms and conditions are specific to the
        Promotional Offers made available by the Company (“Promotional Offer
        TnC”). In the event You receive any Promotional Offer on the Platform,
        in any form as discussed above, You shall be deemed to have read,
        understood and agreed to be bound by the ‘Terms’ and ‘Promotional Offer
        TnC’.
        <br />
        <br />
        Launch Offers: Launch Offers are managed by the Partnered Brands.
        When You shop using Your ZOOOX ID from the “Partnered Brands” (i.e., the
        brands that have partnered with the Company to offer the Benefits), You
        shall be eligible to earn Benefits offered by the Partnered Brands. In
        order to receive the Launch Offers, You shall be deemed to have read,
        understood and agreed to the terms and conditions provided by the
        Partnered Brands along with the Terms and Promotional Offer TnC.   
        <br />
        <br />
        ZOOOX Streaks: Upon accomplishing a defined milestone by completing
        targeted shopping (in value or number) on the third party e-commerce
        sites using ZOOOX ID, You shall be eligible to earn Reward Points and
        Benefits (“ZOOOX Streak”). The milestones for ZOOOX Streaks are defined
        by the Company and You can track Your achievement on the Platform  
        <br />
        <br />
        Brand Streaks: Upon accomplishing a defined milestone by completing
        targeted shopping (in value or number) on the online or offline stores
        of the Partnered Brands, using ZOOOX ID, You shall be eligible to earn
        Reward Points and Benefits (“Brand Streak”). The milestones for Brands
        Streaks are defined and managed by the Partnered Brands and the Company.
        In order to get the benefit of Brand Streaks, You shall be deemed to
        have read, understood and agreed to the terms and conditions provided by
        the Partnered Brands along with the Terms and Promotional Offer TnC.
        <br />
        <br />
        Promotional Offer TnC: 
        <br />
        <br />
        <SmallHeading>Eligibility</SmallHeading>
        <br />
        You are entitled to receive ZOOOX Streaks on every purchase You make
        using ZOOOX ID. However, You shall be eligible to receive Launch Offers
        and Brand Streaks, when You make a purchase from the Partnered Brands
        using ZOOOX ID, who offer the Benefits. 
        <br />
        <br />
        <SmallHeading>Other terms and conditions</SmallHeading>
        <br />
        You acknowledge that the Benefits provided under the Promotional Offers
        are complementary in nature and You cannot maintain any service
        deficiency claims or compensation claims against the Company.   Each
        Benefit is identified by a code and to redeem the Benefit, You must
        enter the code correctly and in the manner specified on the Benefit.  
        <br />
        <br />
        Promotional Offers cannot be purchased using fiat currency.  <br />
        <br />
        The Benefits can be redeemed only once and cannot be reused.  Further,
        one Benefit cannot be clubbed with any Benefit at any time.    <br />
        <br />
        Benefits issued under the Promotional Offers can only be redeemed on the
        Platform.  <br />
        <br />
        You cannot redeem the Benefits for cash and Benefits once redeemed
        cannot be reversed or refunded.   <br />
        <br />
        You cannot transfer the Benefits to any other person.   <br />
        <br />
        Benefits can only be redeemed towards the purchase of eligible products
        using ZOOOX ID from the Partnered Brands who have issued the Benefits.  
        <br />
        <br />
        The validity of the Benefits shall be intimated to You separately at the
        time of issuance of the Benefits.  <br />
        <br />
        In the event the Benefit is in the form of complementary memberships, do
        note the said complementary membership is subject to additional terms
        and conditions of the person offering such membership.  <br />
        <br />
        Misuse of the Promotional Offers in any manner, may result in
        termination or withdrawal of the Benefits.  
        <br />
        <br />
        The Company does not endorse the products sold on the Platform or the
        offerings of the Brand Partners and will not accept any liability
        pertaining to the quality, fitness, delivery or after sales services.
        The Company shall not be responsible towards any delay or failure of
        delivery of any Benefits of a Brand Partner.  <br />
        <br />
        You acknowledge and agree that the Company reserves the right to
        add/alter/modify/change any or all of the terms and conditions with
        respect to the Promotional Offers or discontinue the Benefits, wholly or
        in part, with other benefits at its sole discretion. Any such decision
        of the Company in connection with and incidental to the Promotional
        Offers shall be final and binding on You.  <br />
        <br />
        The Company shall not be liable for any loss or damage, whether direct
        or indirect, resulting from the termination or change of the Benefits
        under the Promotional Offers which are made available to You. Also, the
        Company shall not be responsible for any loss, injury or any other
        liability arising due to Your participation in Promotional Offers.   
        <br />
        <br />
        The Benefits under the Promotional Offers shall be subject to force
        majeure events and on occurrence of such an event, it may be withdrawn
        at the discretion of the Brand Partners and/or the Company. The Company
        shall not be liable in the event it fails to fulfill any of its
        obligations with respect to the Benefits due to any force majeure event
        or change in law or for any other reasons beyond its control.  <br />
        <br />
        Supplemental terms and conditions for Reward Points (“Reward Points
        Terms”)
        <br />
        You shall be eligible to get Reward Points by signing up on the
        Platform, referring friends to the Platform, for carrying out e-commerce
        transactions that You undertake using ZOOOX ID with the Partnered
        Brands.    <br />
        <br />
        The Reward Points awarded on transaction with the Partnered Brands shall
        be governed (such as time of credit, reversal, expiry period etc.) by
        the terms and conditions issued by the Partnered Brands along with the
        Terms and Cashback Terms.  <br />
        <br />
        ‍Copyright © All rights reserved.
        <br />
        <br />
        <br />
        <br />
        <br />
      </Text>
    </MainDiv>
  );
};

export default TermsOfUse;
