import React from "react";
import Header from "../components/Layout/Header";
import styled from "styled-components";
import { BREAKPOINTS } from "../theme/breakpoints";

const MainDiv = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
`;
const Heading = styled.div`
  margin-top: 62px;
  color: #200c32;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 50.958px; /* 106.162% */
  letter-spacing: -1.44px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 40px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 24px;
    margin-top: 55px;
    text-align: start;
    width: 90%;
  }
`;
const Text = styled.p`
  margin-top: 48px;
  text-align: justify;
  width: 70%;
  color: #7f7f7f;
  font-size: 18px;
  font-family: "Albert Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.18px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 80%;
  }

  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 90%;
    font-size: 16px;
    margin-top: 20px;
  }
`;
const SmallHeading = styled.p`
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.18px;
`;
const PrivacyPolicy = () => {
  return (
    <MainDiv>
      <Header />
      <Heading>Privacy Policy</Heading>
      <Text>
        This privacy policy (“Privacy Policy”) specifies the manner in which
        Your personal data and other information is collected, received, stored,
        processed, disclosed, transferred, dealt with or otherwise handled by
        the Company in relation to the services offered on the Platform
        (“Services”). This Privacy Policy does not apply to information that You
        provide to, or that is collected by, any third-party through the
        Platform, and any third-party sites that You access or use in connection
        with the services  (defined hereinafter) offered on the Platform
        <br />
        <br />
        Please read the Privacy Policy carefully prior to using or registering
        on the Platform or accessing any material, information or availing any
        Services through the Platform.
        <br />
        <br />
        By visiting the Platform or setting up/creating an account on the
        Platform for availing the Services and clicking on the “Continue to
        accept” button provided at the sign-up page  for on the Platform, users
        (“You”, “Your” as applicable) accept and agree to be bound by the terms
        and conditions of this Privacy Policy. This Privacy Policy is
        incorporated into Our terms of use (“Terms”) and shall be read
        harmoniously and in conjunction with the Terms. All capitalized terms
        used and not defined in this Privacy Policy shall have the meaning
        ascribed to them under the Terms.
        <br />
        <br />
        This Privacy Policy (i) is will be considered to be an electronic record
        under the Indian data protection laws including the Information
        Technology Act, 2000 read with rules and regulations made thereunder and
        is generated by a computer system; and (ii) will not require any
        physical, electronic, or digital signature by the Company or by You.
        <br />
        <br />
        <SmallHeading>Collection of Information</SmallHeading>
        <br />
        We collect Personal Information (defined below) from You when You
        register or set up an account with Us or login on the Platform. You may
        browse certain sections of the Platform without being registered with
        Us. However, to avail the Services on the Platform You are required to
        set up an Account with Us. 
        <br />
        <br />
        This Privacy Policy applies to the following information:
        <br />
        <br />
        1.     Information You give Us: When You start using the Platform, You
        may provide certain information to Us voluntarily while registering on
        Our Platform for availing Our Services, such as mobile number, Permanent
        Account Number (PAN), signature, email address, date of birth,
        photograph (selfie), identity and address proof such as passport,
        driver’s license, voter ID, or any other government issued identity
        proof document, communication and residential address details, location
        and source of income, etc. We will collect this information through
        various means and in various places through the Platform, including
        account registration forms, contact us forms, or when You otherwise
        interact with the Platform including at customer support.   We may also
        ask You for certain financial information, including Your bank account
        details and type, Unified Payment Interface (UPI) ID, Indian financial
        system code (IFSC) of Your bank, and/or other payment related details or
        other payment method data, and debit instructions to process payments.
        We may ask You to provide certain additional information about Yourself
        on a case-to-case basis. All information disclosed by You shall be
        deemed to be disclosed willingly and without any coercion. No liability
        pertaining to the
        authenticity/genuineness/misrepresentation/fraud/negligence of the
        information disclosed shall lie on the Company nor will the Company be
        in any way responsible to verify any information obtained from You.
        <br />
        Certain features of the Platform may be restricted, unavailable or
        unusable if You choose not to provide certain information.
        <br />
        The above information is collectively referred to as (“Personal
        Information”).    
        <br />
        <br />
        2.     Location based information: When and if You download and/or use
        the Platform through Your mobile or computer, We explicitly seek
        permissions from You to get the required information from such device.
        We may receive information about Your location, Your Internet Protocol
        Address (“IP Address”), and/or Your mobile device, including a unique
        identifier number for Your device. We may use this information to
        provide You with location-based Services including but not limited to
        search results and other personalized content. You can withdraw Your
        consent at any time by disabling the location-tracking functions on Your
        mobile. However, this may affect Your enjoyment of certain
        functionalities on the Platform. In addition to the above, We identify
        and use Your IP Address to also help diagnose problems with Our server,
        resolve such problems and administer the Platform. Your IP Address is
        also used to help identify You and to gather broad demographic
        information.
        <br />
        <br />
        3.     SMS information: After obtaining Your specific consent to allow
        the Platform to access Your SMS inbox and contacts, We may collect
        relevant information from text messages (SMS) received by You from
        providers of services and/or products in relation to e-commerce
        transactions and such other information as may be required to provide
        better access to Our Services to You. 
        <br />
        <br />
        4.     Electronic mail (e-mail) information: When you link your email
        (either ZOOOX ID or email provided by any other third party email
        service providers) to the Platform and/or create a ZOOOX ID on the
        Platform, We may access such email accounts and collect data in relation
        to e-commerce transactions. We may also collect and securely store Your
        email account details, including Your login credentials, e-mails
        received in your email account (including any attachments thereto, the
        email metadata, etc.). We only read emails related to electronic
        commerce shopping such as order value, shipment delivery, order details,
        biller details etc., and do not read or access any personal emails. For
        the sake of clarity, the Company employs automated processes for
        accessing and analyzing information from Your linked email accounts and
        no humans are allowed to access or read your emails. You can provide Us
        access to one or more of Your e-mail accounts by connecting such emails
        to the Platform.   
        <br />
        <br />
        5.   Non – Personal and Automatic Information: We may also collect
        certain non-personal information, such as Your IP Address, web request,
        operating system, browser type, URL, internet service provider,
        aggregate user data, software and hardware attributes, pages You visit,
        and cookie information, etc., which will not identify You specifically
        (“Non-Personal Information”), while You browse, access or use the
        Platform. We receive and store Non–Personal Information by the use of
        data collection devices such as “cookies” on certain pages of the
        Platform, in order to help and analyze Our Platform flow, track user
        trends, measure promotional effectiveness, and promote trust and safety.
        We offer certain additional features on the Platform that are only
        available through the use of a “cookie”. We place both permanent and
        temporary cookies in Your computer/mobile device’s storage drive.
        <br />
        <br />
        6.   User communications: If You choose to post messages on Our message
        boards, chat rooms or other message areas or leave feedback, We will
        collect and store such information You provide to Us. We retain this
        information as necessary to resolve disputes, provide customer support,
        respond to queries, and inquiries, and troubleshoot problems and improve
        the Services. If You send us correspondence, such as emails or letters,
        or if other users or third parties send us correspondence about Your
        activities or postings on the Platform, We may collect and retain such
        information into a file specific to You for responding to Your request
        and addressing concerns in relation to Your use of the Platform.
        <br />
        <br />
        Your Personal Information, Non-Personal Information and other
        information listed above shall together be referred to as “Your
        Information”. 
        <br />
        <br />
        All information gathered by the Company shall be stored on servers, log
        files and any other storage system owned by the Company or by third
        parties in India. Our primary goal in doing so is to provide You a safe,
        efficient, smooth, and customized experience on the Platform.   
        <br />
        <br />
        The information collected allows Us to provide the Services and features
        on the Platform that most likely meet Your needs, and to customize the
        Platform to make Your experience safer and easier. More importantly,
        while doing so, We collect Your Information from You that We consider
        necessary for achieving this purpose.  
        <br />
        <br />
        We also use cookies to allow You to enter Your password less frequently
        during a session on the Platform. Most cookies are “session cookies,”
        meaning that they are automatically deleted from Your storage drive at
        the end of a session. To provide a better experience to You, We also
        store certain “session cookies” on Your device, so that You do not lose
        Your last browsed page and can continue using the Platform seamlessly in
        case of network outage or unexpected sign out from the Platform. You are
        always free to decline Our cookies if Your browser permits, although in
        that case, You may not be able to use certain features or Services being
        provided on the Platform or You may be required to re-enter Your
        password each time You log – in or access the Platform during a session.
         
        <br />
        <br />
        In general, You can browse the Platform without telling Us who You are
        or revealing any Personal Information about Yourself. In such a case, We
        will only collect and store the Non – Personal Information. Once You
        give us Your Personal Information, You are not anonymous to Us. Wherever
        possible, while providing the information to Us, We indicate which
        fields are mandatory and which fields are optional for You. You always
        have the option to not provide the Personal Information to Us through
        the Platform by choosing to not use a particular Service, which requires
        You to provide such information. We may automatically track certain
        information about You based upon Your behavior on the Platform. We use
        this information to do internal research on Your demographics,
        interests, and behavior to better understand, protect and serve You.
        This information is compiled and analyzed by the Company on an
        aggregated basis and not individually, in a manner that does not
        specifically identify You.   
        <br />
        <br />
        We shall be entitled to retain Your Information for such duration as may
        be required for the purposes specified hereunder and will be used by Us
        only in accordance with this Privacy Policy. You acknowledge that if We
        determine that any information You have provided or uploaded violates
        the terms of this Privacy Policy, We have the right, in our absolute
        discretion, to delete or destroy such Your Information without incurring
        any liability to You.
        <br />
        <br />  You hereby agree, acknowledge and grant Your consent enabling Us
        to retain any information provided by You for Our compliance under
        applicable laws including but not limited to the Prevention of the Money
        Laundering Act, 2002 or the Prevention of Money Laundering (Maintenance
        of Records) Rules, 2005 as amended, modified/ reenacted from time to
        time and/ or any other guidelines/policies issued by government/
        statutory authorities or any regulatory bodies in this regard. 
        <br />
        <br />
        <SmallHeading>Use of information</SmallHeading>
        <br />
        We use Your Information, for the following: (i) to provide order
        tracking facility; (ii) to curate offers and rewards specifically for
        You; (iii) to provide an insight into Your shopping pattern(s) in order
        to ensure that You are aware of Your spends pattern so that You make
        wiser decisions; (iv) to provide and improve the Services and features
        on the Platform that You request; (v) to resolve disputes and
        troubleshoot problems; (vi) to help promote a safe service on the
        Platform and protect the security and integrity of the Platform, the
        Services and the users; (vii) to inform You about online and offline
        offers, products, services, and updates; (viii) to provide an insight
        into Your spending pattern(s); (ix) to calculate and display rewards
        earned from various transactions; (x) to customize Your experience on
        the Platform or share marketing material with You; (xi) to detect,
        prevent and protect Us from any errors, fraud and other criminal or
        prohibited activity on the Platform; (xii) to enforce and inform about
        our terms and conditions; (xiii) to process and fulfil Your request for
        Services or respond to Your comments, and queries on the Platform; (xiv)
        to contact You; (xv) to communicate important notices or changes in the
        Services provided by the Company, use of the Platform and the terms
        which govern the relationship between You and Us; (xvi) for any other
        purpose after obtaining Your consent at the time of collection; and
        (xvii) sharing and disclosure of data with third parties. Any collection
        of additional information and documents shall be subject to Your
        consent.  
        <br /> 
        <br />
        We allow You to use Your ZOOOX ID to sign-in or log-in to third party
        websites or applications or platforms. Upon signing-in or logging-in to
        such third-party websites or applications or platforms we will share
        certain basic Personal Information  about You with such third parties. 
        Do note, the processing of such Personal Information shall be subject to
        the terms of use and privacy policy of such Third Party and we request
        You to read the same before using Your ZOOOX ID for such sign-up or
        login.  
        <br />
        <br />
        You also specifically agree and consent to Us collecting, storing,
        processing, transferring, and sharing Your Information with third
        parties such as with entities registered under applicable laws, with
        third party payment gateways, etc., solely for the purpose of providing
        Services to You (including processing Your transaction requests for the
        Services).
        <br />
        <br />
        We may occasionally ask You to complete optional online surveys. These
        surveys may ask You for Your contact information and demographic
        information (like zip code, age etc.,). We use this information to
        tailor Your experience at the Platform, providing You with content that
        We think You might be interested in and to display content according to
        Your preferences. We use Your Personal Information to send You
        promotional emails, however, We will provide You the ability to opt-out
        of receiving such emails from Us. If You opt out, the Company may still
        send You non-promotional emails, such as emails about the Services and
        Your Account on the Platform. Unless and until, You explicitly give Your
        consent to Us, to do so, We will not share Your Personal Information
        with another user of the Platform and vice versa.
        <br />
        <br />
        Notwithstanding anything contained herein, the Company may, in
        compliance with applicable laws in India, process Your Personal
        Information without obtaining Your consent in the event: (a) You have
        voluntarily provided Your Personal Information to the Company, and have
        not indicated that You do not consent to the use of such Personal
        Information by Us; (b) the purpose is for fulfilling any obligation
        under any law for the time being in force in India on any person to
        disclose any information to the state or any of its instrumentalities,
        subject to such processing being in accordance with the provisions
        regarding disclosure of such information in any other law for the time
        being in force; (c) the purpose is for compliance with any judgment or
        decree or order issued under any law for the time being in force in
        India, or any judgment or order relating to claims of a contractual or
        civil nature under any law for the time being in force outside India;
        (d) the purpose is for responding to a medical emergency involving a
        threat to the life or immediate threat to Your health or any other
        individual; (e) the purpose is for taking measures to provide medical
        treatment or health services to any individual during an epidemic,
        outbreak of disease, or any other threat to public health; or (f) the
        purpose is for taking measures to ensure safety of, or provide
        assistance or services to, any individual during any disaster, or any
        breakdown of public order.  
        <br />
        <br />
        <SmallHeading>Sharing of Information</SmallHeading>
        <br />
        Access to Your Personal Information will be provided to certain of Our
        employees strictly on a need-to-know basis. With Your consent, We may
        also disclose Your Personal Information to third party vendors,
        partnered organizations, consultants, and other service providers who
        work for the Company or provide Services through the Platform and are
        bound by contractual obligations to keep such Personal Information
        confidential and use it only for the purposes for which We disclose it
        to them. This disclosure may be required for Us, for instance, to
        provide You access to Our Services including for processing redemption
        of rewards for gift vouchers, gift cards, fiat currency etc., to
        facilitate and assist Our marketing and advertising
        activities/initiatives, for undertaking auditing or data analysis, or to
        prevent, detect, mitigate, and investigate fraudulent or illegal
        activities related to Our Services. We do not disclose Your Personal
        Information to third parties for their marketing and advertising
        purposes without Your explicit consent.  
        <br />
        <br />
        The Company may disclose Your Information, to the extent necessary: (i)
        to comply with applicable laws and to respond to lawful requests and
        legal process, (ii) to protect the rights and property of the Company,
        Our users, and others, including to enforce the Terms, and (iii) in an
        emergency to protect the personal safety and assets of the Company, the
        users, or any person. In such an event the Company is in no manner
        responsible for informing You or seeking Your approval or consent.
        <br />
        <br />
        We may, in compliance with applicable laws, share/transfer/assign all of
        Your Information with any other business entity(ies), in the event of a
        merger, sale, re-organization, amalgamation, joint ventures, assignment,
        restructuring of business or transfer or disposition of all or any
        portion of Our business.   
        <br />
        <br />
        <SmallHeading>Security Precautions and Measures  </SmallHeading>
        <br />
        Our Platform has reasonable security measures and safeguards in place to
        protect Your privacy and Personal Information from loss, misuse,
        unauthorized access, disclosure, destruction, and alteration of the
        information in compliance with applicable laws. Further, whenever You
        change or access Your Account on the Platform or any information
        relating to it, We offer the use of a secure server. We cannot however
        ensure or warrant the security of any information You transmit to the
        Company or guarantee that Your Information provided for availing the
        Services or Platform may not be accessed, disclosed, altered, or
        destroyed by a breach of any of Our security measures and safeguards.
        While We will have adequate security protocols in place, We request You
        to ensure that at all times You secure and have in place adequate
        security protocols to protect Your Information against any unauthorized
        access.
        <br />
        <br />
        Our use of Your Information received from Your Gmail accounts through
        Google APIs will adhere to Google API Services User Data Policy,
        including the limited use requirements.   
        <br />
        <br />
        This Privacy Policy and the security controls and practices implemented
        by the Company to protect Your Personal Information shall be the
        reasonable security practices and procedures under section 43A of the
        Information Technology Act, 2000.
        <br />
        <br />
        <SmallHeading>
          Links to Other Third – party Sites and collection of information
        </SmallHeading>
        <br />
        Our Platform may provide links to other third - party Platforms (“Third
        – Party Sites”) that may collect Your Personal Information including
        Your IP Address, browser specification, or operating system. The Company
        is not in any manner responsible for the security of such information or
        their privacy practices or content of those third–party sites.
        Additionally, You may also encounter “cookies” or other similar devices
        on certain pages of the Third – Party Sites and it is hereby clarified
        by the Company that it does not control the use of cookies by these
        Third – Party Sites. These third-party service providers and Third-Party
        Sites may have their own privacy policies governing the storage and
        retention of Your Information that You may be subject to. This Privacy
        Policy does not govern any information provided to, stored on, or used
        by these third-party providers and Third-Party Sites. We recommend that
        when You enter a Third-Party Site, You review the Third-Party Site’s
        privacy policy as it relates to safeguarding of Your Information. You
        agree and acknowledge that We are not liable for the information
        published in search results or by any Third-Party Sites. ‍
        <br />
        <br />
        <SmallHeading>Manage access to Your Gmail Account</SmallHeading>
        <br />
        You can manage access to Your Gmail account and review the access
        provided to Us (including the account access, access to You Google
        services, etc.,) by going to the ‘security section’ of Your Google
        account.   
        <br />
        <br />
        To learn more about how Google helps users share their data safely,
        please visit: https://support.google.com/accounts/answer/10130420?hl=en 
        <br />
        <br />
        <SmallHeading>Public Posts</SmallHeading>
        <br />
        You may provide Your feedback, reviews, testimonials, etc. on the
        Platform on Your use of the Services provided by the Company (“Posts”).
        Any content or Personal Information and Posts that You share or upload
        on the publicly viewable portion of the Platform will be publicly
        available, and can be viewed by other users. Your Posts shall have to
        comply with the conditions relating to use of Platform as mentioned in
        the Terms. The Company retains an unconditional right to remove and
        delete any Post or such part of the Post that, in the opinion of the
        Company, does not comply with the conditions in the Terms. The Company
        reserves the right to use, reproduce and share Your Posts for any
        purpose. If You delete Your Posts from the Platform, copies of such
        Posts may remain viewable in archived pages, or such Posts may have been
        copied or stored by other Users.
        <br />
        <br />
        <SmallHeading>Your Consent and Changes to Privacy Policy</SmallHeading>
        <br />
        Withdrawal of consent: You may choose to withdraw Your consent provided
        hereunder at any point in time. Such withdrawal of the consent must be
        sent in writing to support@zooox.org. In case You do not provide Your
        consent or later withdraw Your consent, We request You not to access the
        Platform or use the Services and also reserve the right to not provide
        You any Services on the Platform. In such a scenario, the Company may
        delete Your Information or de-identify it so that it is anonymous and
        not attributable to You. In the event, the Company retains the Personal
        Information post withdrawal or cancellation of Your consent, it shall
        retain it only for the period permitted under applicable laws.
        <br />
        <br />
        Managing Your Personal Information and Correcting Inaccuracies: You may
        correct or update any information online of Your Personal Information.
        You also have a right to (a) ask Us for a summary of Personal
        Information being processed by Us and the processing activities
        undertaken by Us with respect to such Personal Information; (b) the
        identities of all other third-party entities with whom Your Personal
        Information has been shared by Us, along with a description of the
        Personal Information so shared, except when such sharing is pursuant to
        a written request by such entity who obtains the information for the
        purpose of prevention or detection or investigation of offenses or cyber
        incidents, or for prosecution or punishment of offenses. You have the
        right to ask Us to correct inaccurate or incomplete or misleading
        Personal Information concerning You or update Your Personal Information
        (which You cannot update Yourself online) by contacting
        support@zooox.org. In the event of loss of access details, You may
        retrieve the same or receive new access details by sending an e-mail to:
        support@zooox.org.   
        <br />
        <br />
        Right to Erasure:  This is also known as ‘the right to be forgotten’
        and, in simple terms, enables You to request the deletion or removal of
        Your Personal Information where there is no compelling reason for Us to
        keep it. Such right of erasure can be exercised by writing to Us at
        support@zooox.org. On Our receipt of such a request for erasure, We
        shall delete the Personal Information stored by Us as per Your request,
        as well as ensure that any third party service provider processing Your
        Personal Information on behalf of Us deletes all such Personal
        Information. This is not a general right to erasure; there are
        exceptions, such as when the law requires Us to retain certain Personal
        Information about You or where such retention is required for providing
        the Services to You, etc.  
        <br />
        <br />
        Right to Nominate: You shall have the right to nominate, in a manner
        prescribed by the Indian government, any other individual, who shall, in
        the event of Your death or incapacity, exercise Your rights in
        accordance with applicable law in India.
        <br />
        <br />
        Use of a Consent Manager: You are permitted to give, manage, review or
        withdraw Your consent provided to the Company to process Your Personal
        Information through a ‘consent manager’. A consent manager is an entity
        that is registered with the Indian Data Protection Board. The consent
        manager is accountable to You and can assist You in providing or
        withdrawing Your consent to Us to process Your Personal Information on
        Your behalf. Such consent managers must comply with the obligations
        imposed on them as may be prescribed by the Indian government. 
        <br />
        <br />
        Changes To Our Privacy Policy: We reserve the unconditional right to
        change, modify, add, or remove portions of this Privacy Policy at any
        time, by notifying You of such changes. Any changes or updates will be
        effective immediately. You should review this Privacy Policy regularly
        for changes. You can determine if changes have been made by checking the
        “Last Updated” legend above. . While we will undertake best efforts to
        intimate you whenever we make material changes to our Privacy Policy,
        You can determine when this Privacy Policy was last modified by
        referring to the “Last Updated” legend above. It shall be Your
        responsibility to check the Privacy Policy periodically for changes.
        Your continued use and access of the Platform shall signify Your
        acceptance of the amended Privacy Policy and Your consent to be legally
        bound by the same.  
        <br />
        <br />
        <SmallHeading>Data Retention and Deletion</SmallHeading>
        <br />
        We shall be entitled to retain Your Information for such duration as may
        be required for the purposes specified hereunder or for such other
        periods as may be required under the applicable laws and the said
        information will be used by Us only in accordance with this Privacy
        Policy.  However, We may retain Your Information if we believe it may be
        necessary to prevent fraud or future abuse, to enable Us to exercise our
        legal rights and/or defend against legal claims or if required by law or
        for other legitimate purposes. When the retention period for Your
        Personal Information as outlined above expires, We will actively
        destroy/delete such Personal Information from our systems. We may,
        however, continue to retain Your Personal Information in anonymized form
        for analytical and research purposes.
        <br />
        <br />
        We do not retain or use data obtained via Google Workspace APIs to
        develop, improve, or train AI/ML models. All data is accessed only to
        provide services and is not stored, shared, or repurposed beyond its
        intended use. We comply with Google API Services Data Policy and
        industry security standards to ensure user data remains private and
        protected.
        <br />
        <br />
        You acknowledge that if We determine that any information You have
        provided or shared violates the terms of this Privacy Policy, We have
        the right, in our absolute discretion, to delete or destroy such of Your
        Personal Information without incurring any liability to You.
        <br />
        <br />
        <SmallHeading>Grievance Officer</SmallHeading>
        <br />
        If You have any grievance with respect to the Platform or the Services,
        You may write to the grievance officer, the name and contact details
        have been provided below: 
        <br />
        Name: Mayank Chauhan
        <br />
        Email: mayank@zooox.org
        <br />
        <br />
        <SmallHeading>Questions?</SmallHeading>
        <br />
        Please feel free to contact us at support@zooox.org regarding any
        questions on the Privacy Policy. The Grievance Redressal Officer shall
        dispose of any complaint in accordance with the applicable laws.
        <br />
        <br />
        In the event You have exhausted Your opportunity of redressing Your
        grievances with respect to Your Personal Information through the
        Grievance Officer, You may raise Your concern to the Data Protection
        Board of India in the manner prescribed under applicable laws in India. 
        <br />
        <br />
        While You have the right to raise complaints with both the Company and
        the Data Protection Board, please note that registering false or
        frivolous complaints with the Data Protection Board is a punishable
        offense.   
        <br />
        <br />
        <br />
        <br />
          
      </Text>
    </MainDiv>
  );
};

export default PrivacyPolicy;
